import { API_V1 } from "@/services/config/main-axios";

class InitService {
  Payment(queries) {
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${queries.accessToken}`,
    //   },
    // };
    return API_V1.post(`/transaction/payment`, {
      authority: `${queries.authority}`,
    });
  }
  Check(queries) {
    // let config = {
    //   headers: {
    //     Authorization: `Bearer ${queries.accessToken}`,
    //   },
    // };
    return API_V1.post(`/transaction/check`, {
      authority: `${queries.authority}`,
    });
  }
}

export default new InitService();
