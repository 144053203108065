<template>
  <v-app id="inspire" class="iransans">
    <v-app-bar app flat color="background-secondary">
      <v-container>
        <v-row align="center" justify="center">
          <v-img
            width="56"
            max-width="56"
            sizes="56"
            src="./assets/logo.png"
          ></v-img>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-main class="background">
      <router-view />
    </v-main>
    <v-footer padless color="background-secondary">
      <v-col class="text-center" cols="12">
        <strong>درگاه نو، شیوه جدید پرداخت</strong>
        <p class="mb-0 text-gray--text">www.dargahno.com</p>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
