<template>
  <v-container>
    <v-overlay :value="loading" color="background" opacity="1" absolute>
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="errorState" color="background" opacity="1" absolute>
      <v-alert prominent type="error" max-width="600">
        <v-row align="center">
          <v-col class="grow"> خطایی رخ داده است لطفا مجددا تلاش کنید</v-col>
        </v-row>
      </v-alert>
    </v-overlay>
    <v-row v-if="paymentData">
      <v-col class="px-2" cols="12" md="4" order="2" order-md="2">
        <ShopInfo />
        <SecurityTips />
      </v-col>
      <v-col
        class="px-2"
        cols="12"
        md="8"
        order="1"
        order-md="1"
        align-self="stretch"
      >
        <MainContent />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainContent from "@/components/MainContent";
import ShopInfo from "@/components/ShopInfo";
import SecurityTips from "@/components/SecurityTips";
import { InitService } from "@/services";
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "Home-view",
  components: {
    ShopInfo,
    SecurityTips,
    MainContent,
  },
  data: () => ({
    loading: true,
    errorState: null,
  }),
  computed: {
    ...mapGetters({
      paymentData: types.init.getters.GET_PAYMENT_DATA,
      checkPaymentData: types.init.getters.GET_CHECK_PAYMENT_DATA,
    }),
  },
  mounted() {
    this.getPayment(this.$route.query);
  },
  methods: {
    ...mapActions({
      setPaymentData: types.init.actions.SET_PAYMENT_DATA,
      setCheckPaymentData: types.init.actions.SET_CHECK_PAYMENT_DATA,
    }),
    getPayment(queries) {
      InitService.Payment(queries)
        .then((response) => {
          // console.log(response.data);
          this.setPaymentData(response.data);
          this.repetitiveCheckPayment(this.$route.query);
        })
        .catch((error) => {
          this.errorState = error.status;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    repetitiveCheckPayment(queries) {
      setInterval(() => {
        InitService.Check(queries)
          .then((response) => {
            this.setCheckPaymentData(response.data);
            if (response.data.status === 1) {
              window.location.href =
                this.paymentData.callback_url +
                "&status=" +
                response.data.status;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 10000);
    },
  },
};
</script>
