export const init = {
  getters: {
    GET_PAYMENT_DATA: "GET_PAYMENT_DATA",
    GET_CHECK_PAYMENT_DATA: "GET_CHECK_PAYMENT_DATA",
  },

  mutations: {
    MUTATE_PAYMENT_DATA: "MUTATE_PAYMENT_DATA",
    MUTATE_CHECK_PAYMENT_DATA: "MUTATE_CHECK_PAYMENT_DATA",
  },

  actions: {
    SET_PAYMENT_DATA: "SET_PAYMENT_DATA",
    SET_CHECK_PAYMENT_DATA: "SET_CHECK_PAYMENT_DATA",
  },
};
