import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        "background-secondary": "#fff",
        background: "#edf1f7",
        text: "#000",
        "text-gray": "#8F9BB3",
        "text-gray-2": "#fff",
        primary: "#007bff",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#dc3545",
        info: "#007bff",
        success: "#28a745",
        warning: "#ffc107",
      },
      dark: {
        "background-secondary": "#222b45",
        background: "#151a30",
        text: "#fff",
        "text-gray": "#8F9BB3",
        "text-gray-2": "#000",
        primary: "#007bff",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#ff3d71",
        info: "#007bff",
        success: "#28a745",
        warning: "#ffc107",
      },
    },
  },
});
