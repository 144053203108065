<template lang="html">
  <v-card rounded="lg" flat class="mt-4" color="background-secondary">
    <v-card-text>
      <v-container class="mt-3">
        <v-row justify="center" class="mb-1">
          <v-col>
            <h3 class="error--text mb-3">نکات امنیتی</h3>
            <ul>
              <li
                class="mb-3 font-regular-14"
                v-for="tip in tips.data"
                :key="tip"
                v-html="tip"
              ></li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import tips from "/public/data/tips.json";

export default {
  name: "security-tips",
  props: [],
  mounted() {

  },
  data() {
    return {
      tips
    };
  },
  methods: {},
  computed: {}
};
</script>
