<template lang="html">
  <v-card rounded="lg" flat color="background-secondary" v-if="paymentData">
    <v-card-text>
      <v-container class="mt-3">
        <!--        <v-row justify="center" class="mb-1">-->
        <!--          <v-col class="text-center d-flex justify-center">-->
        <!--            <v-img-->
        <!--              width="64"-->
        <!--              max-width="64"-->
        <!--              sizes="64"-->
        <!--              src="../assets/logo.png"-->
        <!--            ></v-img>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <v-row justify="center" class="mb-1">
          <v-col class="background text-center">
            <span class="text-gray--text">نام فروشگاه</span>
          </v-col>
          <v-col class="background text-center">
            <span class="text--text">
              {{ paymentData.store_name ? paymentData.store_name : "-" }}
            </span>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-1">
          <v-col class="background text-center">
            <span class="text-gray--text">شماره پشتیبانی</span>
          </v-col>
          <v-col class="background text-center">
            <span class="text--text">
              {{ paymentData.store_phone ? paymentData.store_phone : "-" }}
            </span>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-1">
          <v-col class="background text-center">
            <span class="text-gray--text">وبسایت فروشگاه</span>
          </v-col>
          <v-col class="background text-center">
            <span class="text--text" v-html="storeWebsiteLink">
              <!--              {{ paymentData.storeWebsite ? paymentData.storeWebsite : "-" }}-->
            </span>
          </v-col>
        </v-row>
        <v-row justify="center" class="mb-1">
          <v-col class="background text-center">
            <span class="text-gray--text">کد پذیرنده</span>
          </v-col>
          <v-col class="background text-center">
            <span class="text--text">
              {{ paymentData.factor_number ? paymentData.factor_number : "-" }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script lang="js">
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "shop-info",
  props: [],
  mounted() {

  },
  data() {
    return {};
  },
    methods: {

    },
    computed: {
      ...mapGetters({
        paymentData: types.init.getters.GET_PAYMENT_DATA,
        checkPaymentData: types.init.getters.GET_CHECK_PAYMENT_DATA
      }),
      storeWebsiteLink() {
        if (this.paymentData.store_website) {
          return '<a href="https://' + this.paymentData.stor_website + '" target="_blank">' + this.paymentData.store_website + '</a>'
        } else {
          return '-'
        }
      }
    }
}
</script>
