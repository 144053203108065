import * as types from "@/store/types";

const state = {
  payment: [],
  checkPayment: [],
};

const getters = {
  [types.init.getters.GET_PAYMENT_DATA]: (state) => {
    return state.payment;
  },
  [types.init.getters.GET_CHECK_PAYMENT_DATA]: (state) => {
    return state.checkPayment;
  },
};

const mutations = {
  [types.init.mutations.MUTATE_PAYMENT_DATA]: (state, payload) => {
    state.payment = payload;
  },
  [types.init.mutations.MUTATE_CHECK_PAYMENT_DATA]: (state, payload) => {
    state.checkPayment = payload;
  },
};

const actions = {
  [types.init.actions.SET_PAYMENT_DATA]: ({ commit }, payload) => {
    commit(types.init.mutations.MUTATE_PAYMENT_DATA, payload);
  },
  [types.init.actions.SET_CHECK_PAYMENT_DATA]: ({ commit }, payload) => {
    commit(types.init.mutations.MUTATE_CHECK_PAYMENT_DATA, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
