import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  Accept: "*/*",
};

const API_V1 = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_V1,
  headers: headers,
});

API_V1.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    return Promise.reject(error.response);
  }
);

export { API_V1 };
