<template lang="html">
  <v-card width="460" class="mt-3" outlined flat rounded v-if="paymentData">
    <v-img
      class="white--text align-end"
      height="240"
      src="../assets/logo.svg"
      gradient="to top right, rgba(237,241,247,.2), rgba(255,255,255,.6)"
    >
      <v-card-text>
        <v-container class="pt-0 mb-0 mb-md-3">
          <v-row justify="space-between" align="center">
            <v-col cols="auto">
              <v-img width="56" :src="bankDetails?.bank_logo"></v-img>
            </v-col>
            <v-col cols="auto" class="text--text font-regular-20">
              {{ bankDetails?.bank_title }}
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pt-0 mb-0 mb-md-3">
          <v-row justify="space-between" align="center">
            <p class="text--text mb-0 mx-5">شماره کارت :</p>
            <v-col
              class="d-flex align-center justify-center pt-0"
              cols="12"
              style="direction: ltr"
            >
              <span
                class="text-center mt-4 mx-2 mx-md-3 text--text"
                :class="{
                  'font-bold-36': $vuetify.breakpoint.mdAndUp,
                  'font-bold-24': !$vuetify.breakpoint.mdAndUp,
                }"
                v-for="bankCard in bankFormatter"
                :key="bankCard"
              >
                {{ bankCard }}
              </span>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pt-3 mb-0 mb-md-3">
          <v-row justify="space-between" align="center">
            <v-col cols="12">
              <p class="text--text text-center font-medium-20 mb-0 ms-2">
                {{ paymentData.account_owner_name }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-img>
  </v-card>
</template>

<script lang="js">
import { banks } from "@/utility/banks";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "bank-card",
  props: [],
  mounted() {
  },
  data: () => ({
    banks
  }),
  filters: {
    bankFormatter(value) {
      let split = value.toString()?.split("");
      const indexes = [4, 9, 14];
      indexes.forEach(index => {
        split?.splice(index, 0, " ");
      });
      return split?.join("");
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      paymentData: types.init.getters.GET_PAYMENT_DATA,
      checkPaymentData: types.init.getters.GET_CHECK_PAYMENT_DATA
    }),
    bankFormatter() {
      return this.paymentData.card_number.match(/.{1,4}/g) ?? [];
    },
    bankDetails() {
        return banks.find(bank => {
            return bank.card_no === +(this.paymentData.card_number.slice(0, 6));
        });
    }
  }
}
</script>

<style scoped lang="scss"></style>
