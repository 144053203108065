import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import init from "./modules/init";

export default new Vuex.Store({
  modules: {
    init,
  },
});
