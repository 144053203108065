<template lang="html">
  <v-card
    color="background-secondary"
    flat
    height="100%"
    rounded="lg"
    v-if="paymentData"
  >
    <v-card-text class="d-flex justify-center">
      <v-card
        color="background-secondary"
        class="d-flex justify-center align-center flex-column"
        flat
        width="460"
      >
        <BankCard />
        <v-container>
          <v-row>
            <v-col>
              <h2 class="font-medium-36 text-center mt-6">
                {{ paymentData.price | thousandMask }}
                <span class="font-regular-16 text-gray--text">ریال</span>
              </h2>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="px-0">
          <v-row align="end">
            <v-col>
              <VueCountdown :time="15 * 60 * 1000" @end="canceled()">
                <template slot-scope="props">
                  {{
                    props.minutes < 10 ? "0" + props.minutes : props.minutes
                  }}:{{
                    props.seconds < 10 ? "0" + props.seconds : props.seconds
                  }}
                  <v-progress-linear
                    :value="100 - (props.totalSeconds * 100) / (15 * 60)"
                    color="accent"
                    height="10"
                    rounded
                  ></v-progress-linear>
                </template>
              </VueCountdown>
            </v-col>
            <v-col class="ps-0" cols="auto">
              <v-icon>mdi-clock-outline</v-icon>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="px-0">
          <v-row>
            <v-col>
              <v-btn
                block
                color="primary"
                depressed
                outlined
                v-clipboard:copy="paymentData.card_number"
                v-clipboard:success="clipboardSuccessHandler"
              >
                <v-icon left small>mdi-content-copy</v-icon>
                کپی شماره کارت
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                color="primary"
                depressed
                outlined
                v-clipboard:copy="paymentData.price.toString()"
                v-clipboard:success="clipboardSuccessHandler"
              >
                <v-icon left small>mdi-content-copy</v-icon>
                کپی مبلغ
              </v-btn>
            </v-col>
            <v-col>
              <v-btn block color="error" depressed outlined @click="canceled()">
                انصراف</v-btn
              >
            </v-col>
          </v-row>
          <v-snackbar
            v-model="snackbar"
            text
            color="success"
            top
            max-width="100"
          >
            کپی شد
          </v-snackbar>
        </v-container>
        <template v-if="checkPaymentData.status !== 1">
          <v-alert class="mt-3" text type="warning">
            لطفا به سه رقم سمت راست مبلغ دقت کنید و مبلغ را دقیق واریز کنید.
            تشخیص واریزی شما از طریق این عدد می باشد.
          </v-alert>
          <v-alert text type="info">
            لطفا از طریق موبایل بانک یا اینترنت بانک خود این مبلغ را به شماره
            کارتی که مشاهده می کنید کارت به کارت کنید و در این صفحه منتظر بمانید
            تا پرداخت شما به صورت خودکار تایید گردد.
          </v-alert>
        </template>
        <template v-else>
          <v-alert text type="success"> پرداخت با موفقیت انجام شد</v-alert>
        </template>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script lang="js">
import BankCard from "@/components/BankCard";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "main-content",
  props: [],
  components: {
    BankCard,
    VueCountdown
  },
  filters: {
    thousandMask(val) {
      return new Intl.NumberFormat().format(val);
    }
  },
  mounted() {

  },
  data: () => ({
    snackbar: false
  }),
  methods: {
    clipboardSuccessHandler() {
      this.snackbar = true;
    },
    canceled() {
      window.location.href = this.paymentData.callback_url +"&status=-1";
    }
  },

  computed: {
    ...mapGetters({
      paymentData: types.init.getters.GET_PAYMENT_DATA,
      checkPaymentData: types.init.getters.GET_CHECK_PAYMENT_DATA
    })
  }
};
</script>
