export const banks = [
  {
    card_no: 504172,
    bank_name: "resalat",
    bank_title: "بانک رسالت",
    bank_logo: "/images/resalat.svg",
    main_color: "#26eeb900",
    color: "#26eeb9",
    color2: "#26eeb9",
  },
  {
    card_no: 627381,
    bank_name: "ansar",
    bank_title: "بانک انصار",
    bank_logo: "/images/ansar-05.svg",
    main_color: "#26eeb900",
    color: "#26eeb9",
    color2: "#26eeb9",
  },
  {
    card_no: 636214,
    bank_name: "ayandeh",
    bank_title: "بانک آینده",
    bank_logo: "/images/ayande-03.svg",
    main_color: "#3388391d",
    color: "#338839",
    color2: "#338839",
  },
  {
    card_no: 502938,
    bank_name: "dey",
    bank_title: "بانک دی",
    bank_logo: "/images/day-12.svg",
    main_color: "#0dffffff",
    color: "#0dffff",
    color2: "#0dffff",
  },
  {
    card_no: 627412,
    bank_name: "eghtesad_novin",
    bank_title: "بانک اقتصادنوین",
    bank_logo: "/images/eghtesad-04.svg",
    main_color: "#1a662891",
    color: "#1a6628",
    color2: "#1a6628",
  },
  {
    card_no: 628157,
    bank_name: "etebari_tosee",
    bank_title: "موسسه اعتباری توسعه",
    bank_logo: "/images/tose-11.svg",
    main_color: "#33000000",
    color: "#330000",
    color2: "#330000",
  },
  {
    card_no: 505416,
    bank_name: "gardeshgari",
    bank_title: "بانک گردشگری",
    bank_logo: "/images/gardeshgari-20.svg",
    main_color: "#29344143",
    color: "#293441",
    color2: "#293441",
  },
  {
    card_no: 639599,
    bank_name: "ghavvamin",
    bank_title: "بانک قوامین",
    bank_logo: "/images/ghavvamin-31.svg",
    main_color: "#1afff400",
    color: "#1afff4",
    color2: "#1afff4",
  },
  {
    card_no: 627488,
    bank_name: "kar_afarin",
    bank_title: "بانک کارآفرین",
    bank_logo: "/images/karafarin-06.svg",
    main_color: "#333a881d",
    color: "#333a88",
    color2: "#333a88",
  },
  {
    card_no: 502910,
    bank_name: "kar_afarin",
    bank_title: "بانک کارآفرین",
    bank_logo: "/images/karafarin-06.svg",
    main_color: "#333a881d",
    color: "#333a88",
    color2: "#333a88",
  },
  {
    card_no: 603770,
    bank_name: "keshavarzi",
    bank_title: "بانک کشاورزی",
    bank_logo: "/images/keshavarzi-07.svg",
    main_color: "#29aa9a22",
    color: "#29aa9a",
    color2: "#29aa9a",
  },
  {
    card_no: 639217,
    bank_name: "keshavarzi",
    bank_title: "بانک کشاورزی",
    bank_logo: "/images/keshavarzi-07.svg",
    main_color: "#29aa9a22",
    color: "#29aa9a",
    color2: "#29aa9a",
  },
  {
    card_no: 628023,
    bank_name: "maskan",
    bank_title: "بانک مسکن",
    bank_logo: "/images/maskan-26.svg",
    main_color: "#1a000000",
    color: "#1a0000",
    color2: "#1a0000",
  },
  {
    card_no: 639370,
    bank_name: "mehr_e_eghtesad",
    bank_title: "بانک مهر اقتصاد",
    bank_logo: "/images/mehreghtesad-14.svg",
    main_color: "#1a656565",
    color: "#1a6565",
    color2: "#1a6565",
  },
  {
    card_no: 606373,
    bank_name: "mehr_e_iranian",
    bank_title: "بانک قرض الحسنه مهر ایرانیان",
    bank_logo: "/images/mehriran-15.svg",
    main_color: "#1a209b1c",
    color: "#1a209b",
    color2: "#1a209b",
  },
  {
    card_no: 603799,
    bank_name: "meli",
    bank_title: "بانک ملی ایران",
    bank_logo: "/images/melli-22.svg",
    main_color: "#1a00e0d7",
    color: "#1a00e0",
    color2: "#1a00e0",
  },
  {
    card_no: 610433,
    bank_name: "mellat",
    bank_title: "بانک ملت",
    bank_logo: "/images/mellat-02.svg",
    main_color: "#1aba0b22",
    color: "#1aba0b",
    color2: "#1aba0b",
  },
  {
    card_no: 991975,
    bank_name: "mellat",
    bank_title: "بانک ملت",
    bank_logo: "/images/mellat-02.svg",
    main_color: "#1aba0b22",
    color: "#1aba0b",
    color2: "#1aba0b",
  },
  {
    card_no: 111111,
    bank_name: "ok",
    bank_title: "همه کارتخوان‌ها",
    bank_logo: "/images/shetab.svg",
    main_color: "#1a00e0d7",
    color: "#1a00e0",
    color2: "#1a00e0",
  },
  {
    card_no: 622106,
    bank_name: "parsian",
    bank_title: "بانک پارسیان",
    bank_logo: "/images/pasargad-21.svg",
    main_color: "#29980000",
    color: "#299800",
    color2: "#299800",
  },
  {
    card_no: 502229,
    bank_name: "pasargad",
    bank_title: "بانک پاسارگاد",
    bank_logo: "/images/pasargad-10.svg",
    main_color: "#4d000000",
    color: "#4d0000",
    color2: "#4d0000",
  },
  {
    card_no: 639347,
    bank_name: "pasargad",
    bank_title: "بانک پاسارگاد",
    bank_logo: "/images/pasargad-10.svg",
    main_color: "#4d000000",
    color: "#4d0000",
    color2: "#4d0000",
  },
  {
    card_no: 627760,
    bank_name: "post_bank",
    bank_title: "پست بانک ایران",
    bank_logo: "/images/post-09.svg",
    main_color: "#1a14763e",
    color: "#1a1476",
    color2: "#1a1476",
  },
  {
    card_no: 589463,
    bank_name: "refah",
    bank_title: "بانک رفاه",
    bank_logo: "/images/refahkargaran-28.svg",
    main_color: "#0d000000",
    color: "#0d0000",
    color2: "#0d0000",
  },
  {
    card_no: 627961,
    bank_name: "saanat_va_maadan",
    bank_title: "بانک صنعت و معدن",
    bank_logo: "/images/sanatmadan-16.svg",
    main_color: "#339b814c",
    color: "#339b81",
    color2: "#339b81",
  },
  {
    card_no: 603769,
    bank_name: "saderat",
    bank_title: "بانک صادرات",
    bank_logo: "/images/saderat-29.svg",
    main_color: "#1a2d2a68",
    color: "#1a2d2a",
    color2: "#1a2d2a",
  },
  {
    card_no: 621986,
    bank_name: "saman",
    bank_title: "بانک سامان",
    bank_logo: "/images/saman-01.svg",
    main_color: "#1a7dcdf1",
    color: "#1a7dcd",
    color2: "#1a7dcd",
  },
  {
    card_no: 639607,
    bank_name: "sarmayeh",
    bank_title: "بانک سرمایه",
    bank_logo: "/images/sarmaye-17.svg",
    main_color: "#29bd9ff5",
    color: "#29bd9f",
    color2: "#29bd9f",
  },
  {
    card_no: 589210,
    bank_name: "sepah",
    bank_title: "بانک سپه",
    bank_logo: "/images/sepah-24.svg",
    main_color: "#33ffc400",
    color: "#33ffc4",
    color2: "#33ffc4",
  },
  {
    card_no: 504706,
    bank_name: "shahr",
    bank_title: "بانک شهر",
    bank_logo: "/images/shahr-25.svg",
    main_color: "#29ffafb2",
    color: "#29ffaf",
    color2: "#29ffaf",
  },
  {
    card_no: 502806,
    bank_name: "shahr",
    bank_title: "بانک شهر",
    bank_logo: "/images/shahr-25.svg",
    main_color: "#29ffafb2",
    color: "#29ffaf",
    color2: "#29ffaf",
  },
  {
    card_no: 639346,
    bank_name: "sina",
    bank_title: "بانک سینا",
    bank_logo: "/images/sina-23.svg",
    main_color: "#1a277eb5",
    color: "#1a277e",
    color2: "#1a277e",
  },
  {
    card_no: 627353,
    bank_name: "tejarat",
    bank_title: "بانک تجارت",
    bank_logo: "/images/tejarat-13.svg",
    main_color: "#290fadc7",
    color: "#290fad",
    color2: "#290fad",
  },
  {
    card_no: 585983,
    bank_name: "tejarat",
    bank_title: "بانک تجارت",
    bank_logo: "/images/tejarat-13.svg",
    main_color: "#290fadc7",
    color: "#290fad",
    color2: "#290fad",
  },
  {
    card_no: 636949,
    bank_name: "tejarat",
    bank_title: "بانک حکمت",
    bank_logo: "/images/hekmat-30.svg",
    main_color: "#29563288",
    color: "#295632",
    color2: "#295632",
  },
  {
    card_no: 627648,
    bank_name: "tosee_saderat",
    bank_title: "بانک توسعه صادرات",
    bank_logo: "/images/tosesaderat-18.svg",
    main_color: "#1f6d6900",
    color: "#1f6d69",
    color2: "#1f6d69",
  },
  {
    card_no: 502908,
    bank_name: "tosee_taavon",
    bank_title: "بانک توسعه تعاون",
    bank_logo: "/images/tosetaavon-19.svg",
    main_color: "#290076ff",
    color: "#290076",
    color2: "#290076",
  },
];
